import { useState } from 'react'
import './App.css'
import LandingPage from './pages/landing_page'
import ContactUs from './pages/contact_us'
import TermsOfUse from './pages/TermsOfUse'
import PrivacyPolicy from './pages/PrivacyPolicy'
import CustomCursor from './components/custom_cursor'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ScrollToTop from './scroll_to_top'

function App() {

  return (
    <Router>
      <div>
        {/* <CustomCursor /> */}
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
