import React from 'react';

const Stats = () => {
    return (
        <div className='flex flex-col items-start my-8'>
            <h1 className='w-1/2 font-bold text-3xl gradient-text text-left'>Trusted by Thousands</h1>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4 w-full my-4'>
                <div className='flex flex-col items-center border-b-2 md:border-b-0 md:border-r-2 border-green-300 pb-4 md:pr-4'>
                    <h1 className='text-6xl font-semibold gradient-text'>5000+</h1>
                    <p className='text-white text-lg'>Users</p>
                </div>

                <div className='flex flex-col items-center border-b-2 md:border-b-0 md:border-r-2 border-green-300 pb-4 md:pr-4'>
                    <h1 className='text-6xl font-semibold gradient-text'>6k+</h1>
                    <p className='text-white text-lg'>App Downloads</p>
                </div>

                <div className='flex flex-col items-center pb-4 md:pr-4'>
                    <h1 className='text-6xl font-semibold gradient-text'>10</h1>
                    <p className='text-white text-lg'>Countries</p>
                </div>
            </div>
        </div>
    );
}

export default Stats;
