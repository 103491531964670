import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        message: '',
    });

    const [loading, setLoading] = useState(false);

    // Initialize EmailJS
    // React.useEffect(() => {
    //     emailjs.init('Mu9uz6TgIOy51x8LY'); 
    // }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // setLoading(true);
        // emailjs.send(
        //     'service_nyse3bh',  
        //     'template_oauvlbq',  
        //     formData            
        // ).then(() => {
        //     console.log('Message sent successfully');
        //     alert('Message sent successfully!');
        //     setLoading(false);
        // }).catch((error) => {
        //     console.error('Failed to send message', error);
        //     alert('Failed to send message');
        //     setLoading(false)
        // });
    };

    return (
        <div className="w-full max-w-lg mx-auto md:my-8 p-2 md:p-6">
            <form id="contact-form" onSubmit={handleSubmit}>
                {/* Name Field */}
                <div className="mb-4">
                    <input
                        type="text"
                        name="from_name" 
                        value={formData.from_name}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-black rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                        placeholder="Name"
                        required
                    />
                </div>

                {/* Email Field */}
                <div className="mb-4">
                    <input
                        type="email"
                        name="from_email" 
                        value={formData.from_email}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-black rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                        placeholder="Email"
                        required
                    />
                </div>

                {/* Message Field */}
                <div className="mb-4">
                    <textarea
                        name="message" 
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full px-3 py-2 text-black rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
                        placeholder="Message"
                        rows="4"
                        required
                    />
                </div>

                
                <button
                    type="submit"
                    className="bg-green-500 hover:bg-green-600 text-black font-bold py-2 px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                >
                    {loading ? 'Sending...' : 'Send Message'}
                    
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
