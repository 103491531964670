import React from 'react';

const Intro = () => {
    return (
        <div className='flex justify-center my-5' id="home">
            <div className='text-center'>
                <h1 className="text-3xl md:text-6xl md:w-1/2 mx-auto font-bold" style={{ backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #018f27)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                    Stream Live TV's, Events, and Videos
                </h1>
                <div className='bg-green-500 rounded-full py-2 px-4 my-3 font-bold text-lg hover:scale-105 w-fit mx-auto'>Download</div>
            </div>
        </div>
    )
}

export default Intro;