import React from "react";
import { FaStar } from 'react-icons/fa';
import phoneImg from '../assets/phones.png';
import AppCut from '../assets/App-cut.png';

const Features = () => {
    return (
        <div className="flex flex-col items-start mt-4" id="features">
            <h2 className="font-bold text-3xl mb-8 gradient-text">
                Why Kanema Online Is The Perfect App For You
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
                <div className="col-span-1 md:col-span-2 bg-[#212121] bg-opacity-30 border border-white border-opacity-20 px-4 pt-4 flex flex-col items-start rounded-2xl">
                    <h3 className="text-green-500 font-bold text-2xl">Huge Library</h3>
                    <h3 className="text-white font-bold text-2xl text-left">Dive into a Massive Library of Entertainment</h3>
                    <img src={phoneImg} alt="Phone" className="w-full h-auto mt-4" />
                </div>
                <div className="col-span-1 bg-[#212121] bg-opacity-30 border border-white border-opacity-20 p-4 flex flex-col items-start justify-between rounded-lg">
                    <div className="flex flex-col items-start">
                        <h3 className="text-green-500 font-bold text-2xl">APP STORE</h3>
                        <h3 className="text-white font-bold text-left text-2xl">
                            Top rated app in <br /> Malawi
                        </h3>
                    </div>
                    <div className="flex flex-col items-start">
                        <h1 className="text-6xl font-bold gradient-text">4.9</h1>
                        <div className="flex space-x-1 mt-2">
                            {/* Star icons */}
                            {[...Array(5)].map((_, i) => (
                                <FaStar key={i} className="text-green-500" size={32} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-span-1 md:col-span-3 bg-[#212121] bg-opacity-30 border border-white border-opacity-20 px-4 pt-4 flex flex-col md:flex-row rounded-lg">
                    <div className="flex flex-col items-start md:w-1/2">
                        <h3 className="text-green-500 font-bold text-2xl">Precise Performance</h3>
                        <h3 className="text-white font-bold text-left text-2xl">
                            Powered by Cinect for an Amazing, Smooth Experience
                        </h3>
                    </div>
                    <img src={AppCut} alt="Performance" className="mt-4 md:mt-0 md:ml-4 w-full md:w-1/2 h-auto" />
                </div>
            </div>
        </div>
    );
};

export default Features;
