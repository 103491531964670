import React from "react";
// import Footer from "../components/Footer";
// import "./policy.css";
// import Typography from "../components/Typography"; // Assuming the code for the Typography component is in a separate file
// import NavbarPolicy from "../components/Navbar-policy";
import Navbar from "../components/navBar";
import Footer from "../components/footer";

const TermsOfUse = () => {
  return (
    <div className="">
      {/* <NavbarPolicy /> */}
      <Navbar />

      <div className="privacy-policy md:p-8 text-white text-left">
        <div className="privacy-policy-header mb-8">
          <h1 className="text-3xl font-bold mb-2">
            KANEMA ONLINE TERMS OF USE
          </h1>
          <p>Updated 07/07/2023</p>
        </div>

        <div className="privacy-policy-content">
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">1. OVERVIEW</h2>
            <p>
              1.1 Kanema Online is a video streaming platform that operates
              through{" "}
              <a
                href="http://www.kanemaonline.com"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                www.kanemaonline.com 
              </a>
               and Mobile Applications; iOS and Android available on Apple Store
              and Google Play Store. Kanema Online users can watch Live TVs,
              Live Events, and Video; Movies, TVs Shows, Dramas, Comedies, etc.
              By accessing our website services, users agree that they have read
              and understood and agree to be bound by these general Kanema
              Online Terms of Use. Kanema Online services are provided by Static
              Computers Limited.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              2. CHANGES TO THESE TERMS OF USE
            </h2>
            <p>
              2.1 These general Terms of Use for Kanema Online may be amended
              periodically.
            </p>
            <p>
              2.2 Notice of any changes will be provided through a notification
              pop-up when using our service channels' website and Apps or email.
              It is your responsibility to review the terms of use and any
              changes whenever you intend to use Kanema Online services.
            </p>
            <p>
              2.3 If you do not agree with the changes to these terms of use,
              you have the option to cancel your Kanema Online subscription
              before the changes take effect.
            </p>
            <p>
              2.4 You acknowledge that we may periodically suspend, discontinue,
              or modify information, services, and content on the Kanema Online
              service channels.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              3. WHO MAY USE THE KANEMA SERVICES AND MEMBERSHIP
            </h2>
            <p>
              <span className="block">
                3.1 Kanema Online services are available to users who are at
                least 13 years of age. Parental guidance is advised for minors
                at all times.
              </span>
              <span className="block">
                3.2 Registration and subscription may be required and register
                by providing the necessary information as requested by the
                system.
              </span>
              <span className="block">
                3.3 Only those who register can access our services; we reserve
                the right to refuse registration or utilization of the Kanema
                Online service with or without providing reasons.
              </span>
              <span className="block">
                3.4 The user's Kanema Online membership will remain active until
                terminated. Charges associated with the use of our services will
                be billed to the user's payment method on the specified payment
                date indicated on the user's account page.
              </span>
              <span className="block">
                3.5 Users of Kanema Online may provide one or more payment
                methods. By using our services, the user authorizes us to charge
                any payment method associated with their account.
              </span>
              <span className="block">
                3.6 The subscription fee for Kanema Online may change
                periodically. Users will receive a notification of any price
                changes 30 days prior to the changes being applied to their
                account. Users have the option to disagree with the price
                changes and are allowed to cancel their subscription at any
                time. Failure to settle the payment due to insufficient funds or
                expiration may result in the suspension of the user's account.
              </span>
              <span className="block">
                3.7 Users have the option to cancel their Kanema Online
                membership at any time. Payments made to Kanema Online are
                non-refundable, and we do not provide refunds for partial
                membership periods or unused content. To deactivate an account,
                users can go to 'Account' and follow the provided instructions.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">4. ACCESS DEVICE</h2>
            <p>
              <span className="block">
                4.1 In order to access the Kanema Online services, users must
                have Web-based, Android, or iOS-based devices. System
                requirements may vary from time to time.
              </span>
              <span className="block">
                4.2 In order to access and use the Kanema Online Service, your
                Access Devices must meet the minimum technical specifications.
              </span>
              <span className="block">
                4.3 We will in no way be responsible for your inability to
                access our Service and Content due to limitations specific to
                your Access Devices.
              </span>
              <span className="block">
                4.4 You, at your own cost, are responsible for obtaining and
                maintaining the Access Devices, adequate and reliable internet
                access, and all information technology and telecommunication
                facilities, equipment, services, products, software, systems,
                materials, applications, platforms, and the like, ("Technology")
                needed to access the internet or to use our services.
              </span>
              <span className="block">
                4.5 If an Access Device does not belong to you, you confirm and
                promise that you have obtained permission from the owner of the
                Access Device to download or stream the Content onto the
                relevant Access Device and to use the Access Device to receive
                and access the Kanema Online Service.
              </span>
              <span className="block">
                4.6 We are not responsible for any internet access charges,
                service provider charges, and data usage charges. These charges
                must be paid by you or the owner of the Access Device.
              </span>
              <span className="block">
                4.7 The quality of the Kanema Online Service and the Content,
                and your ability to use the Kanema Online Service and to stream
                our content, including the time it takes to stream or download
                Content and associated costs, may be affected by various
                factors, such as your location.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              5. USER DATA AND DETAILS
            </h2>
            <p>
              <span className="block">
                5.1 Users are required to provide accurate and complete
                information at all times, including during the registration
                process of the Kanema Online account.
              </span>
              <span className="block">
                5.2 Users have the ability to update their information at any
                time or by contacting us. Kanema Online will send an email
                confirming the update or changes to the user's details.
              </span>
              <span className="block">
                5.3 For more information on how we use user data and the
                responsibilities of users, please refer to our privacy policy,
                which can be accessed at our website.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              6. PASSWORD AND ACCOUNT ACCESS
            </h2>
            <p>
              <span className="block">
                6.1 Upon completing registration, a Kanema Online account will
                be created for the user using the provided information during
                registration, including a valid email address, phone number,
                password, and location. This same data will be used for logging
                in to the Kanema account.
              </span>
              <span className="block">
                6.2 Users are advised to keep their account passwords private.
                By granting access to others for their Kanema Online account,
                users agree that those individuals are acting on their behalf
                and accept our terms of use, privacy policy, and responsibility
                for any changes made to the account. Users are also responsible
                for any activity that occurs on their account.
              </span>
              <span className="block">
                6.3 Users have the option to update or change/reset their
                passwords at any time.
              </span>
              <span className="block">
                6.4 Kanema Online will not be held responsible for any loss or
                damage caused by unauthorized access to a user's password and
                account credentials.
              </span>
              <span className="block">6.5 SECURITY</span>
              <span className="block">
                6.6 Users may not intentionally attempt or engage in any actions
                that interfere with or compromise the security of Kanema Online
                services. Any behavior that disrupts the security and stability
                of Kanema Online may result in legal action.
              </span>
              <span className="block">
                6.7 Users should take reasonable steps to prevent any compromise
                of their account security or stability. It is advised not to
                click on any links unless they are provided by us through our
                official emails and channels.
              </span>
              <span className="block">
                6.8 If a user believes their data security has been compromised,
                they should immediately contact info@kanemaonline.com.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              8. ALLOWED USE AND ACCESS
            </h2>
            <p>
              <span className="block">
                8.1 Users agree to use Kanema Online services, including all
                associated features and functionalities, in compliance with
                applicable laws and any restrictions on the use of our services
                or content. Users are not authorized to:
              </span>
              <ul className="list-disc pl-8 mb-4">
                <li>
                  Use or attempt to use any automated tools (such as robots, web
                  spiders, web crawlers, harvesting bots, or scrapers) to use or
                  access our services without written consent.
                </li>
                <li>
                  Insert any code or manipulate Kanema Online content or
                  services in any way.
                </li>
                <li>
                  Reproduce, modify, distribute, publish, license, display,
                  archive, offer for sale, interfere with, or use any content or
                  information obtained through Kanema Online services.
                </li>
                <li>
                  Post any information or content that is or may be defamatory,
                  unlawful, harassing, explicit, offensive, misleading, or
                  infringing on privacy rights.
                </li>
                <li>
                  Impersonate other users or falsely represent one's identity to
                  violate their privacy.
                </li>
                <li>
                  Post, upload, email, or transmit any material designed to
                  disrupt, destroy, or limit the functionality of Kanema Online
                  software, hardware, or any associated telecommunications
                  equipment.
                </li>
              </ul>
              <span className="block">
                8.2 Site visitors may access and use the Kanema Online website
                <a
                  href="http://www.kanemaonline.com"
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  www.kanemaonline.com
                </a>
                or the information provided on the website lawfully and in
                accordance with these terms.
              </span>
              <span className="block">
                8.3 Users are encouraged to report any information or content on
                our website that they believe does not comply with our terms of
                reference by contacting us at info@kanemaonline.com.
              </span>
              <span className="block">
                8.4 Users agree to comply with directions from Kanema Online or
                our mediators regarding the access and use of Kanema Online
                services.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              10. TERMINATION OF THIS AGREEMENT
            </h2>
            <p>
              <span className="block">
                10.1 Users may terminate their agreement by canceling their
                subscriptions and deactivating their Kanema Online account. To
                deactivate an account, users can go to their account settings
                and follow the provided instructions. It is recommended to do
                this before the next subscription billing to avoid further
                charges. Kanema Online will not be responsible for refunds and
                payment charges made prior to the cancellation date.
              </span>
              <span className="block">
                10.2 Kanema Online reserves the right to terminate this
                agreement with any user of our services, subscriptions, and
                access to our services at any time and for any reason. Users may
                receive prior notice of the termination.
              </span>
              <span className="block">
                10.3 We may terminate this agreement and revoke a user's access
                to Kanema Online immediately in the following circumstances:
              </span>
              <ul className="list-disc pl-8 mb-4">
                <li>Breach of these terms of use and our License agreement.</li>
                <li>
                  Attempting to access restricted content from restricted
                  locations or using restricted devices.
                </li>
                <li>Compliance with legal obligations.</li>
              </ul>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">11. COMMUNICATION</h2>
            <p>
              <span className="block">
                11.1 We will communicate with users regarding their accounts
                (e.g., password changes, payment authorization, confirmation
                messages, and notices) through pop-up notifications when using
                our services and via text or email using the user credentials
                provided during registration.
              </span>
              <span className="block">
                11.2 When referring to Kanema Online as "We" or "Us," it
                includes our service providers, agents, affiliates, and
                representatives. However, users should verify the relevance of
                any details before making decisions, entering into agreements,
                or conducting transactions. We shall not be held responsible for
                any loss or liabilities. Official communications will be made
                through account@kanemaonline.com and info@kanemaonline.com or
                via pop-up notifications when using{" "}
                <a
                  href="http://www.kanemaonline.com"
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  www.kanemaonline.com.
                </a>
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">12.CONTACT DETAILS</h2>

            <p>
              <span className="block">Static Computers Inc</span>
              <span className="block">Area 47/4</span>
              <span className="block">P. O. Box 400</span>
              <span className="block">Lilongwe, 3</span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265211776931" className="text-blue-500">
                  +265 211 776931
                </a>
              </span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265888776931" className="text-blue-500">
                  +265 888 776931
                </a>
              </span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265899776931" className="text-blue-500">
                  +265 899 776931
                </a>
              </span>
              <span className="block">
                Email:{" "}
                <a
                  href="mailto:info@kanemaonline.com"
                  className="text-blue-500"
                >
                  info@kanemaonline.com
                </a>
              </span>
              <span className="block">
                Email:{" "}
                <a
                  href="mailto:info@staticcomputersinc.com"
                  className="text-blue-500"
                >
                  info@staticcomputersinc.com
                </a>
              </span>
            </p>
          </section>

          <section className="mb-8"></section>
        </div>
      </div>
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default TermsOfUse;
