import React from 'react';
import LeftImg from '../assets/Left.png';
import CenterImg from '../assets/Center.png';
import RightImg from '../assets/Right.png';

const HeroSection = () => {
  return (
    <div className="relative md:w-full mx-auto h-[800px] overflow-hidden bg-black ">
      <div className="absolute inset-0 flex flex-col" style={{ transform: 'translateY(-33%)' }}>
        <div className="flex">
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={CenterImg} alt="Image 2" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={RightImg} alt="Image 3" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={CenterImg} alt="Image 2" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
        </div>
        <div className="flex">
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={CenterImg} alt="Image 2" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={RightImg} alt="Image 3" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={RightImg} alt="Image 3" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
        </div>
        <div className="flex">
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={CenterImg} alt="Image 2" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={RightImg} alt="Image 3" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
          <img src={LeftImg} alt="Image 1" className="w-[20%] h-[500px] animate-scroll-medium object-cover px-1 md:px-2" />
          <img src={RightImg} alt="Image 3" className="w-[20%] h-[500px] animate-scroll-fast object-cover px-1 md:px-2" />
        </div>
      </div>
      
      <div className="absolute w-full h-[800px] inset-0 pointer-events-none bg-gradient-to-t from-[#141414] via-transparent to-[#141414] opacity-100"></div>
      <div className="absolute w-full h-[800px] inset-0 pointer-events-none bg-gradient-to-b from-[#141414] via-transparent to-[#141414] opacity-100"></div>
      <div className="absolute w-full h-[800px] inset-0 pointer-events-none bg-gradient-to-r from-[#141414] via-transparent to-[#141414] opacity-80"></div>
      <div className="absolute w-full h-[800px] inset-0 pointer-events-none bg-gradient-to-l from-[#141414] via-transparent to-[#141414] opacity-80"></div>
    
    </div>
  );
};

export default HeroSection;
