import React from "react";
import Logo from '../assets/kanema.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className="my-8 flex flex-col">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-6">
                <div className="col-span-1 md:col-span-2 flex items-center justify-center">
                    <img src={Logo} alt="Logo" className="object-contain w-1/2 md:w-1/3 h-auto" />
                </div>

                <div className="text-white md:hidden items-start py-4">
                    <h3 className="text-xl font-semibold">Stream Live TVs, Events & Videos</h3>
                </div>

                <div className="col-span-1 p-4">
                    <h4 className="text-white font-semibold py-4 text-left">Navigation</h4>
                    <nav className="text-white text-left font-semibold h-full w-full">
                        <ul className="space-y-2">
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="#home">
                                    Home
                                </a>
                            </li>
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="#features">
                                    Features
                                </a>
                            </li>
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="#faq">
                                    FAQ
                                </a>
                            </li>
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="#download">
                                    Download
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className="col-span-1 p-4">
                    <h4 className="text-white font-semibold py-4 text-left">Social Media</h4>
                    <nav className="text-white h-full w-full text-left font-semibold">
                        <ul className="space-y-2">
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="https://youtube.com/@kanemaonline?si=LpWnWLZu-G5dMleG">
                                    Youtube
                                </a>
                            </li>
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="https://www.instagram.com/kanemaonline?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                    Instagram
                                </a>
                            </li>
                            <li className="block rounded hover:hover:text-green-500">
                                <a href="https://www.facebook.com/kanemaonline1">
                                    Facebook
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="text-white md:flex items-start py-4 hidden">
                <h3 className="text-3xl font-semibold">Stream Live TVs, Events & Videos</h3>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between py-4">
                <div className="flex items-start text-white mb-4 md:mb-0">
                    <p className="text-lg font-semibold">© 2024 Kanema - Static Computers Inc</p>
                </div>

                <div>
                    <nav className="flex justify-center items-end text-white w-full text-left font-semibold">
                        <ul className="flex flex-row space-x-2">
                            <Link to="/privacypolicy">
                                <li className="rounded hover:hover:text-green-500">Privacy Policy</li>
                            </Link>
                            <li className="rounded hover:hover:text-green-500">Cookies</li>
                            <Link to="/termsofuse">
                                <li className="rounded hover:text-green-500">Terms & Conditions</li>
                            </Link>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Footer;
