import React from "react";
import AppImg from '../assets/App.png';
import { FaApple } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";

const Download = () => {
    return (
        <div className="w-full border border-white border-opacity-35 rounded-2xl p-8 flex flex-col-reverse md:flex-row" id="download">
            {/* Image on top for mobile and on the right for larger screens */}
            <div className="w-full md:w-1/2 flex flex-col items-start justify-center">
                <h3 className="text-green-500 font-bold text-3xl md:text-left">Download The App</h3>
                <h1 className="text-white font-bold text-2xl md:text-4xl text-center md:text-left">
                    Experience the Future of Streaming Live TVs, Events, and Videos with Kanema Online
                </h1>

                <div className="flex flex-col py-4 md:flex-row mx-auto md:mx-0">
                    <a href="https://apps.apple.com/in/app/kanema-online/id6449297998">
                    <div className="rounded-full bg-green-500 px-8 py-1 mb-2 md:mb-0 md:mr-2 flex items-center hover:scale-105">
                        <FaApple size={32} className="text-black" />
                        <div className="flex flex-col items-start ml-4">
                            <p className="text-black text-sm font-normal">Download on the</p>
                            <p className="text-black text-lg font-semibold">App Store</p>
                        </div>
                    </div>
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.kanemaonline.app&fbclid=IwY2xjawFd7aZleHRuA2FlbQIxMAABHTdARtzhcZo9AqUiHTzehNZERFaSw7w1wO0PpRQBa3g459J-RuHZWEEohA_aem_SErOEoua2B9r0p_ovRk9ag">
                        <div className="rounded-full bg-green-500 px-8 py-1 flex items-center hover:scale-105">
                            <FaGooglePlay size={32} className="text-black" />
                            <div className="flex flex-col items-start ml-4">
                                <p className="text-black text-sm font-normal">Get it on</p>
                                <p className="text-black text-lg font-semibold">Google Play</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <img src={AppImg} alt="Performance" className="w-full md:w-1/2 h-auto mx-auto" />
        </div>
    );
};

export default Download;
