import React from 'react';
import Navbar from '../components/navBar';
import ContactForm from '../components/contact_form';
import Footer from '../components/footer';

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <h1 className='font-bold text-xl md:text-3xl gradient-text'>Do you have further questions?</h1>
            <p className='text-white text-sm md:text-md'>Reach out to us and well get back to you in less than 48 hours.</p>
            <ContactForm />
            <Footer />
        </div>
    )
}

export default ContactUs