import React, { useState } from 'react';
import Logo from '../assets/kanema.png';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("");


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <nav className={`w-full md:w-fit px-4 bg-[#141414] bg-opacity-40 border border-green-500 ${isOpen ? 'rounded-xl' : 'rounded-full'} mx-auto m-4 sticky top-0 z-50`}>
            <div className="flex justify-between items-center h-14">
                <Link to="/">
                    <div className="w-24 h-24 md:mr-6">
                        <img src={Logo} alt="Logo" className="object-contain w-full h-full" />
                    </div>
                </Link>


                <button onClick={toggleMenu} className="sm:hidden text-green-500">
                    {isOpen ? (
                        <IoMdClose size={24} />
                    ) : (
                        <FaBars size={24} />
                    )
                    }

                </button>

                {/* Nav Links - larger screens */}
                <ul className="hidden sm:flex space-x-6 text-white font-bold">
                    <li className="">
                        <a href="#features" onClick={() => setActiveTab("features")} >
                            <div className={`rounded-full px-2 py-1 ${activeTab === "features" ? "bg-green-500" : " bg-none"} `}>Features</div>

                        </a>
                    </li>
                    {/* <li className="">
                        <a href="#faq" onClick={() => setActiveTab("faq")}>
                            <div className={`rounded-full px-2 py-1 ${activeTab === "faq" ? "bg-green-500" : " bg-none"} `}>FAQ</div>
                        </a>
                    </li> */}
                    <li className="">
                        <a href="#download" onClick={() => setActiveTab("download")}>
                            <div className={`rounded-full px-2 py-1 ${activeTab === "download" ? "bg-green-500" : " bg-none"} `}>Download</div>
                        </a>
                    </li>
                </ul>
            </div>

            {/* Mobile nav */}
            {isOpen && (
                <div className="sm:hidden mt-4">
                    <ul className="flex flex-col space-y-4 text-white font-bold text-center">
                        <a href="#home">
                            <li className="hover:text-green-500 flex justify-between">
                                Home
                                <IoIosArrowForward />
                            </li>
                        </a>
                        <a href="#features">
                            <li className="hover:text-green-500 flex justify-between">
                                Features
                                <IoIosArrowForward />
                            </li>
                        </a>
                        {/* <a href="#faq">
                            <li className="hover:text-green-500 flex justify-between">
                                FAQ
                                <IoIosArrowForward />
                            </li>
                        </a> */}
                        <li>
                            <a href="#download">
                                <div className='bg-green-500 rounded-full py-2 px-4 my-3 font-bold text-lg hover:scale-105 w-full mx-auto'>Download</div>
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
