import React from "react"; // Assuming the code for the Typography component is in a separate file
import Navbar from "../components/navBar";
import Footer from "../components/footer";

const PrivacyPolicy = () => {
  return (
    <div className="">
      {/* <Navbar /> */}
      <Navbar/>
      <div className="privacy-policy md:p-8 text-white text-left">
        <div className="privacy-policy-header mb-8">
          <h1 className="text-3xl font-bold mb-2">
            Kanema Online Privacy Policy
          </h1>
          <p>Updated 07/07/2023</p>
        </div>

        <div className="privacy-policy-content">
          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">1. INTRODUCTION</h2>
            <p>
              Kanema Online is an online video streaming platform, streaming
              through website www.kanemaonline.com and Mobile Applications;
              Android and iOS. Kanema Online streams Live TVs, Live Events and
              Videos; Movies, Series, Comedies, Documentaries, TV Shows and etc.
              Viewers are able to Stream and watch Live, and Catchup their
              favorite TV programs and videos.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">2. POLICY PURPOSE</h2>
            <p>
              <span className="block mb-2">
                2.1 This privacy policy explains how we collect, use, share and
                protect your data when using Kanema Online. It also explains
                your rights and how you can contact us if you have any questions
                or concerns.
              </span>
              <span className="block mb-2">
                2.2 This privacy policy applies whenever a person (User, viewer)
                views or uses our platform; website (
                <a
                  href="http://www.kanemaonline.com"
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  www.kanemaonline.com
                </a>
                ), and our App.
              </span>
              <span className="block">
                2.3 We (Kanema Online) constantly urge our users to read this
                policy completely so that you understand before use and in using
                our platforms.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              3. COLLECTION OF PERSONAL DATA
            </h2>
            <p>
              <span className="block">
                3.1 We collect personal data from you when you create an account
                on Kanema Online and when use our platform. The personal data we
                collect are email addresses, phone numbers, passwords, device
                and browser information, and IP address.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">4. USE OF PERSONAL DATA</h2>
            <p>
              <span className="block">
                4.1 The information that may be received and collected is
                basically to provide better services and used:
              </span>
              <ul className="list-disc ml-6">
                <li>
                  For the purpose of processing and creating user account and
                  authenticating your identity to use our services
                </li>
                <li>To offer customer support assistance and communication</li>
                <li>
                  For contractual commitment and agreement with you to access
                  our services.
                </li>
                <li>
                  For research, analytics, administration and improvement of our
                  services
                </li>
              </ul>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              5. YOUR RIGHTS REGARDING PERSONAL DATA
            </h2>
            <p>
              <span className="block">
                5.1 The following are the rights you have regarding personal
                data;
              </span>
              <ul className="list-disc ml-6">
                <li>
                  Right to information: You are entitled to know how your data
                  was obtained, when, why, and by whom it is processed.
                </li>
                <li>
                  Right to rectification: You have the right to rectify any
                  inaccurate or incomplete data.
                </li>
                <li>
                  Right to restrict processing: Meaning where certain conditions
                  apply, you have the right to restrict the processing of your
                  data.
                </li>
                <li>
                  Right to complain: You have the right to complain anytime you
                  feel you have not been helped in the events of practicing
                  these rights. Feel free to contact us by email at{" "}
                  <a
                    href="mailto:info@kanemaonline.com"
                    className="text-blue-500"
                  >
                    info@kanemaonline.com
                  </a>
                </li>
                <li>
                  Right to withdraw consent: You have the right to withdraw any
                  given consent for the processing of your data with the option
                  to delete your account.
                </li>
              </ul>
              <span className="block">
                5.2 Please note that if you contact us regarding your data, we
                may need to verify your identity before proceeding with your
                request.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              6. DATA PROTECTION PRINCIPLES
            </h2>
            <p>
              <span className="block">
                6.1 We always ensure the safety and confidentiality of your
                Data. Before processing any Personal Data, we will always
                confirm and consider your legal rights. We will certainly offer
                information on the processing at your request to guarantee that
                your Data is used fairly and transparently.
              </span>
              <span className="block">
                6.2 We always utilize secure methods for connecting and
                transmitting data, such as HTTPS, and we anonymize your data
                wherever feasible. We will continue to check our systems for
                vulnerabilities and threats.
              </span>
              <span className="block">
                6.3 We always advise you to keep your username and password a
                secret.
              </span>
              <span className="block">
                6.4 Be assured that we will not keep your data for any longer
                than necessary.
              </span>
              <span className="block">
                6.5 However, in rare instances, we cannot guarantee information
                security. Nonetheless, we vow to alert appropriate authorities
                in the event of a data breach. We will also alert you if your
                right or interest is threatened. In the event of any
                vulnerabilities or assaults, rest assured that we will use every
                resource at our disposal to keep the problem from growing.
              </span>
              <span className="block">
                6.6 If you believe your data security has been compromised,
                please contact (info@kanemaonline.com) immediately.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              7. WHO ELSE HAS ACCESS TO YOUR DATA
            </h2>
            <p>
              <span className="block">
                7.1 Be assured that we are not permitted to disclose Personal
                Data with unauthorized parties under this privacy policy. In
                rare circumstances, Kanema Online may be forced by a court of
                law to divulge personal data.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">8. CHILD PROTECTION</h2>
            <p>
              <span className="block">
                8.1 We are entitled to safeguard the information of the children
                that use our sites and applications. We strongly advise users of
                our platforms to be at least 13 years old. We seek the
                monitoring of parents or legal guardians in these cases.
              </span>
              <span className="block">
                8.2 Know that Kanema Online does not collect any data of
                underaged knowingly. If the under-aged have accessed our
                platforms, we should with a thorough investigation revoke the
                usage of our platforms.
              </span>
              <span className="block">
                8.3 Kanema Online may also protect the rights of children that
                are featured in the content streaming on our platforms.
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              9. OUR CONTACT INFORMATION
            </h2>
            <p>
              <span className="block">Static Computers Inc</span>
              <span className="block">Area 47/4</span>
              <span className="block">P. O. Box 400</span>
              <span className="block">Lilongwe, 3</span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265211776931" className="text-blue-500">
                  +265 211 776931
                </a>
              </span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265888776931" className="text-blue-500">
                  +265 888 776931
                </a>
              </span>
              <span className="block">
                Tel:{" "}
                <a href="tel:+265899776931" className="text-blue-500">
                  +265 899 776931
                </a>
              </span>
              <span className="block">
                Email:{" "}
                <a
                  href="mailto:info@kanemaonline.com"
                  className="text-blue-500"
                >
                  info@kanemaonline.com
                </a>
              </span>
              <span className="block">
                Email:{" "}
                <a
                  href="mailto:info@staticcomputersinc.com"
                  className="text-blue-500"
                >
                  info@staticcomputersinc.com
                </a>
              </span>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              10. CHANGES TO THIS POLICY
            </h2>

            <span className="block">
              10.1 We may, at any given time amend or revise this privacy
              policy. Should that happen, you shall be notified of the changes
              by receiving an email from (account@kanemaonline.com) or through
              the pop–u notice when using the Kanema Online platforms
            </span>

            <span className="block">
              10.2 Any modifications will be updated together with the posting
              dates for this privacy policy.
            </span>
          </section>
        </div>

        <div className="privacy-policy-footer mt-8 italic">
          KANEMA’S ONLINE PRIVACY POLICY is committed to guaranteeing the
          security and privacy of your information. By using our platforms or
          services, you agree to the processing of your data as well as any
          other data necessary to improve your experience. If you do not
          understand any of the contents of this privacy statement or have any
          queries about your data, please contact us using the information
          provided above.
        </div>
      </div>
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;