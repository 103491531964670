import React from 'react'
import Navbar from '../components/navBar'
import Intro from '../components/intro'
import Features from '../components/features'
import Stats from '../components/stats'
import FAQ from '../components/faq'
import Download from '../components/download'
import Footer from '../components/footer'
import HeroSection from '../components/hero_section'

function LandingPage() {
  return (
    <div className='w-full'>
        <Navbar />
        <Intro />
        <HeroSection />
        <Features />
        <Stats />
        {/* <FAQ /> */}
        <Download />
        <Footer />
    </div>
  )
}

export default LandingPage